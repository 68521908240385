import React from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from "@mui/material";

const ChildCompanyForm = ({ index, company, onRemove }) => {
    const { values, handleChange, handleBlur } = useFormikContext();

    const childCompany = values.childCompanies[index] || {
        locationName: '',
        contactName: '',
        contactEmail: '',
    };

    return (
        <div className="child-company-form row">
            {/* Location Name */}
            <div className="form-group col-md-3">
                <input
                    type="text"
                    name={`childCompanies[${index}].locationName`}
                    id={`childCompanies[${index}].locationName`}
                    className="form-control"
                    placeholder="Enter Child Company Name"
                    value={childCompany.locationName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <ErrorMessage name={`childCompanies[${index}].locationName`} component="div" className="text-danger mt-2" />
            </div>

            {/* Contact Name */}
            <div className="form-group col-md-2">
                <input
                    type="text"
                    name={`childCompanies[${index}].contactName`}
                    id={`childCompanies[${index}].contactName`}
                    className="form-control"
                    placeholder="Enter Phone Number"
                    value={childCompany.contactName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <ErrorMessage name={`childCompanies[${index}].contactName`} component="div" className="text-danger mt-2" />
            </div>

            {/* Contact Email */}
            <div className="form-group col-md-3">
                <input
                    type="text"
                    name={`childCompanies[${index}].contactEmail`}
                    id={`childCompanies[${index}].contactEmail`}
                    className="form-control"
                    placeholder="Enter Contact Email"
                    value={childCompany.contactEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <ErrorMessage name={`childCompanies[${index}].contactEmail`} component="div" className="text-danger mt-2" />
            </div>

            {/* Remove Button */}
            <div className="form-group col-md-1">
                <Button
                    onClick={onRemove}
                    variant="outlined"
                    color="error"
                    startIcon={<FontAwesomeIcon icon={faTimes} />}>
                    Remove
                </Button>
            </div>
        </div>
    );
};

export default ChildCompanyForm;
