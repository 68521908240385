// CompanySearchSelect.js
import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Typography, Avatar } from "@mui/material";
import axios from 'axios';
import { useFormContext } from '../../context/FormContext';
import { API_BASE_URL } from '../../config';

const CompanySearchSelect = () => {
    const { formData, updateFormData } = useFormContext();
    const [companies, setCompanies] = useState([]);

    const fetchCompanies = async (query) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/search-companies`, {
                params: { q: query, userpost: formData.userType , industryType: formData.industryType }
            });
            setCompanies(response.data);
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    };

    useEffect(() => {
        if (formData.corporationName) {
            fetchCompanies(formData.corporationName);
        }
    }, [formData.corporationName]);

    const handleCompanySelect = (event, newValue) => {
        if (newValue) {
            console.log(newValue)
            updateFormData({
                corporationName: '',
                officeAddress: '',
                phoneNumber:  '',
                faxNumber: '',
                companyId:  null,
                selectedCompany: newValue.company || null,
            });
        }
    };

    return (
        <div className="form-group col-md-7">
            <label htmlFor="companySearch">Search for your company</label>
            <Autocomplete
                id="companySearch"
                options={companies}
                getOptionLabel={(option) => option.companyname}
                onInputChange={(event, value) => fetchCompanies(value)}
                onChange={handleCompanySelect}
                renderOption={(props, option) => (
                    <li {...props} key={option.companyId || option.companyname}> {/* Use companyId or companyname */}
                        <Avatar src={option.logo || "/placeholder.png"} alt="Logo" />
                        <Typography sx={{ ml: 2 }}>{option.companyname}</Typography>
                    </li>
                )}
                renderInput={(params) => <TextField {...params} label="Search Company" />}
            />
        </div>
    );
};

export default CompanySearchSelect;
