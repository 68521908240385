import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Checkbox, FormControlLabel, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { marketingStaff } from "../constants";

const MarketingStaff = ({ prev, next }) => {
    const { formData, updateFormData } = useFormContext();

    const validationSchema = Yup.object({
        marketingStaff: Yup.array()
            .min(1, 'Please select at least one category') // Minimum one selection required
            .required('This field is required'),
    });

    const handleSelectAll = (setFieldValue, values) => {
        if (values.marketingStaff.length === marketingStaff.length) {
            setFieldValue('marketingStaff', []); // Deselect all
        } else {
            setFieldValue('marketingStaff', marketingStaff.map((category) => category.value)); // Select all
        }
    };

    return (
        <div className="container align-items-center mt-5 mb-5 fade-in">
            <div className="header">
                <h2 className="text-start mb-2">Select Your Marketing & Sales Staff Title</h2>
                <p className="text-start mb-4">
                    Select all who should receive an invitation to have an account under your company/organization.
                    <br />
                    These local professionals work directly with seniors and their families in their area on behalf of the company.
                </p>
            </div>
            <Formik
                initialValues={{
                    marketingStaff: formData.marketingStaff || [], // Fallback to empty array if undefined
                }}
                enableReinitialize={true} // Sync form state with context
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    // Update context with the selected marketingStaff
                    updateFormData({ ...formData, marketingStaff: values.marketingStaff });
                    next();
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form className="w-100">
                        <div className="row">
                            <div className="col-md-12 mb-3 text-start">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.marketingStaff.length === marketingStaff.length} // All selected
                                            onChange={() => handleSelectAll(setFieldValue, values)}
                                        />
                                    }
                                    label="Select All"
                                />
                                <div className="checkbox-group">
                                    {marketingStaff.map((category) => (
                                        <FormControlLabel
                                            key={category.value}
                                            control={
                                                <Checkbox
                                                    name="marketingStaff"
                                                    value={category.value}
                                                    checked={values.marketingStaff.includes(category.value)} // Check state
                                                    onChange={() => {
                                                        const currentValues = values.marketingStaff;
                                                        if (currentValues.includes(category.value)) {
                                                            setFieldValue(
                                                                'marketingStaff',
                                                                currentValues.filter((item) => item !== category.value)
                                                            );
                                                        } else {
                                                            setFieldValue('marketingStaff', [...currentValues, category.value]);
                                                        }
                                                    }}
                                                />
                                            }
                                            label={category.label}
                                        />
                                    ))}
                                </div>
                                <ErrorMessage name="marketingStaff" component="div" className="text-danger" />
                            </div>
                        </div>

                        <hr style={{ margin: '20px 0' }} />

                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            >
                                Prev
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon" />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default MarketingStaff;
